import { action, makeObservable } from 'mobx'

import * as api from '../api/bankAccounts.api'
import SharedStore from './shared.store'

export class BankAccountsStore extends SharedStore {
  storeName = 'BankAccountsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  @action fetchSellerBankAccounts = async (username, data) => {
    const resp = await this.childApi.fetchSellerBankAccounts(username, data)
    this.list = resp.data.list
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new BankAccountsStore()
