import { GET, PUT, POST, DELETE } from 'utils/requests.utils'

const BASE_URL = '/common/bank_accounts'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const fetchSellerBankAccounts = (username, data) =>
  GET(`/team_member/sellers/${username}/bank_accounts`, {
    per: 10000,
    ...data,
  })

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)
