const filterMenuItemsByPermissions = (menuItems, permissions) =>
  menuItems?.reduce((filteredItems, item) => {
    if (item.static) {
      // Do not filter static items
      filteredItems.push(item)
    } else if (item.children) {
      // Filtering child items, if no child have permission, do not return parent item
      const filteredChildren = item.children.reduce((filteredChilds, child) => {
        // If permission list include child key
        if (typeof child.key === 'string') {
          permissions.includes(child.key) && filteredChilds.push(child)
        } else if (Array.isArray(child.key)) {
          child.key.some((key) => permissions.includes(key)) && filteredChilds.push(child)
        }
        return filteredChilds
      }, [])
      // Push parent with filtered childs
      item.children.length > 0 &&
        filteredItems.push({
          ...item,
          children: filteredChildren,
        })
    } else {
      // If permission list include parent key
      permissions.includes(item.key) && filteredItems.push(item)
    }

    return filteredItems
  }, [])

export const filteredMenuItemsList = ({ menuItems, withPermissions, permissions }) =>
  withPermissions ? filterMenuItemsByPermissions(menuItems, permissions) : menuItems
