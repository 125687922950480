import { observable, makeObservable, action } from 'mobx'

import { REVIEW_STATES } from 'constants/products.constants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/products.api'

export class ProductsStore extends SharedStore<api.Product> {
  storeName = 'ProductsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable inReviewCount = 0

  @action
  setInReviewCount = (inReviewCount) => (this.inReviewCount = inReviewCount)

  fetchInReviewCount = async () => {
    const resp = await this.childApi.fetchCount({ reviewState: REVIEW_STATES.inReview })
    this.setInReviewCount(resp?.data?.count || 0)

    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export const productsStore = new ProductsStore()
