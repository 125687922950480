import { action, computed, makeObservable, observable, override } from 'mobx'

import { camelToSnakeCase, snakeCaseToCamelCase } from 'utils/nameStyle.utils'

import { INTEGRATIONS } from 'constants/integrations.constants'

import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellerSetting.api'
import apiConnectionsStore from './apiConnections.store'

const initialIntegrationsValue = {
  activeCampaign: [],
  getResponse: [],
  mailChimp: [],
  mailingWork: [],
  klickTipp: [],
  tracify: [],
}

const initialIntegrationsLoading = {
  activeCampaign: false,
  getResponse: false,
  mailChimp: false,
  mailingWork: false,
  klickTipp: false,
  tracify: false,
}

const integrationsNames = {
  activeCampaign: 'buyerListId',
  mailChimp: 'buyerListId',
  getResponse: 'buyerCampaignId',
  mailingWork: 'buyerListId',
  klickTipp: 'buyerListId',
  tracify: 'buyerListId',
}

export class SellerSettingsStore extends SharedStore {
  storeName = 'SellerSettingsStore'
  childApi = api
  @observable integrations = initialIntegrationsValue
  @observable integrationListLoading = initialIntegrationsLoading

  @computed get dataKeys() {
    return {
      apiSettingsKeys: Object.keys(this.item.apiSettings || {}),
      integrationsKeys: Object.keys(this.integrations || {}),
    }
  }

  @override
  get queryParams() {
    const { apiSettingsKeys, integrationsKeys } = this.dataKeys

    return integrationsKeys.reduce((result, integrationKey) => {
      const { length } = integrationKey

      const apiSettingsValue = apiSettingsKeys.reduce((result, apiSettingsKey) => {
        const shouldSetValue =
          apiSettingsKey.includes(integrationKey) &&
          !apiSettingsKey.includes('Id') &&
          !apiSettingsKey.includes('SyncAllContacts')

        if (!shouldSetValue) return result

        const key = apiSettingsKey.slice(length)

        return {
          ...result,
          type: camelToSnakeCase(integrationKey),
          [key]: this.item.apiSettings[apiSettingsKey],
        }
      }, {})

      return {
        ...result,
        [integrationKey]: apiSettingsValue,
      }
    }, {})
  }

  @action setIntegrationListLoading = (type, value) => {
    this.integrationListLoading = {
      ...this.integrationListLoading,
      [type]: value,
    }
  }

  @action setIntegrations = (data) => {
    this.integrations = !!data
      ? {
          ...this.integrations,
          ...data,
        }
      : initialIntegrationsValue
  }

  fetchIntegrationList = async (key, params) => {
    this.setIntegrationListLoading(key, true)
    const resp = await this.childApi.fetchIntegrationsList(params)
    const { data = {}, success } = resp || {}

    if (success || !data.length) {
      this.setIntegrations({ [key]: data })
    }
    this.setIntegrationListLoading(key, false)

    return {
      data,
      success,
    }
  }

  fetchIntegrationTags = (data) => api.fetchIntegrationsTags(data)

  fetchIntegrations = (key, data) => {
    const name = snakeCaseToCamelCase(key)
    const queryParams = {
      ...this.queryParams[name],
      ...data,
    }
    const shouldFetch = this.integrations.hasOwnProperty(name) && !Object.values(queryParams).every((item) => !item)

    if (shouldFetch) {
      return this.fetchIntegrationList(name, data)
    }
  }

  @override
  async updateItem(data, successMessage = I18n.t('react.cabinet.notific.updated')) {
    const { integrationForm, ...restData } = data

    const resp = await this.childApi.updateItem(restData)
    this.setItem(resp)

    notify('success', successMessage)

    if (this.activeConnectionIds[integrationForm]) {
      const apiConnectionResp = await this.root.apiConnectionsStore.fetchItem(this.activeConnectionIds[integrationForm])

      if (apiConnectionResp.success) {
        const { settings = {}, form } = apiConnectionResp.data || {}
        const { apiKey = '', apiEndpoint = '', buyerListId = '', buyerCampaignId = '', ...restSettings } = settings
        const { apiSettings = {} } = this.item

        const camelCaseForm = snakeCaseToCamelCase(form)

        const settingParams = {
          [`${camelCaseForm}ApiKey`]: apiKey,
          [`${camelCaseForm}ApiEndpoint`]: apiEndpoint,
          [`${camelCaseForm}BuyerListId`]: buyerListId,
          [`${camelCaseForm}BuyerCampaignId`]: buyerCampaignId,
        }

        this.childApi.updateItem({
          ...restData,
          apiSettings: {
            ...apiSettings,
            ...settingParams,
          },
        })

        this.fetchIntegrationList(camelCaseForm, {
          ...restSettings,
          apiKey,
          apiEndpoint,
          type: integrationForm,
        })
      }
    }

    return resp
  }

  setApiItem = (item, integrationForm) => {
    this.updateItem({
      ...this.item,
      ...item,
      integrationForm,
    })
  }

  @action setApiSettings = (setting) => {
    this.item = {
      ...this.item,
      apiSettings: {
        ...this.item.apiSettings,
        ...setting,
      },
    }
  }

  @computed get activeConnectionIds() {
    const { activeCampaignConnectionId, getResponseConnectionId, mailChimpConnectionId, mailingWorkConnectionId } =
      this.item

    return {
      [INTEGRATIONS.activeCampaign]: activeCampaignConnectionId,
      [INTEGRATIONS.getResponse]: getResponseConnectionId,
      [INTEGRATIONS.mailChimp]: mailChimpConnectionId,
      [INTEGRATIONS.mailingWork]: mailingWorkConnectionId,
    }
  }

  updateApiSettings = (id, values) => {
    const { apiSettings } = this.item

    const valuesKeys = Object.keys(values)

    const integrationName = snakeCaseToCamelCase(`${id}_${integrationsNames[id]}`)

    const data = valuesKeys.reduce((result, key) => {
      const shouldSetPrefix = key.includes('SyncAllContacts')

      const name = shouldSetPrefix ? key : snakeCaseToCamelCase(`${id}_${key}`)

      return {
        ...result,
        [name]: values[key],
      }
    }, {})

    const { [`${id}SyncAllContacts`]: syncAllContacts, [integrationName]: listId, ...rest } = data

    if (!Object.values(rest).every(Boolean)) {
      data[integrationName] = ''
    }

    this.updateItem({
      ...this.item,
      apiSettings: {
        ...apiSettings,
        ...data,
      },
    })
  }

  constructor(rootStore) {
    super()
    this.root = rootStore
    makeObservable(this)
  }
}

// TODO: remove after refactoring stores structure in cabinets. This store is imported to elo-publisher, payer, publisher, sales-team-member, team-member
export default new SellerSettingsStore({
  apiConnectionsStore: apiConnectionsStore,
})
