import React, { useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import Avatar from 'ui/Avatar'

import { PROFILE_TYPES, getProfileDetails } from 'constants/profile.constants'
import { ProfileSwitchingModal } from './ProfileSwitchingModal'

interface ProfileSwitcherProsp {
  avatar: string
  username: string
  profileType: string
  isCollapsed?: boolean
  isHovered?: boolean
  hoverOff?: () => void
}

export const ProfileSwitcher: React.FC<ProfileSwitcherProsp> = observer(
  ({ avatar, username, profileType, ...props }) => {
    const [switchingModalOpen, setSwitchingModalOpen] = useState<boolean>(false)
    const { isCollapsed, isHovered, hoverOff } = props

    const toggleSwitchingModal = () => setSwitchingModalOpen(!switchingModalOpen)
    const collapseSideBarWhenToggledModal = () => {
      hoverOff()
      toggleSwitchingModal()
    }

    const profileDetails = getProfileDetails()[PROFILE_TYPES[profileType]]

    const accountsClasses = classNames('side-menu__content-accounts', {
      'side-menu__content-accounts--collapsed': isCollapsed && !isHovered,
    })
    const chevronIconClasses = classNames('fas fa-chevron-right', {
      collapsed: isCollapsed && !isHovered,
    })

    return (
      <>
        <button id='sideMenuProfileSwitcher' type='button' className={accountsClasses} onClick={toggleSwitchingModal}>
          <div className='side-menu__content-accounts__current'>
            <Avatar img={avatar} />
            <div className='side-menu__content-accounts__details'>
              <div className='side-menu__content-accounts__username'>{username}</div>
              <div className='side-menu__content-accounts__account-type'>
                {I18n.t('react.shared.profile_type_account', { profileType: profileDetails.title })}
              </div>
            </div>
          </div>
          <i className={chevronIconClasses} />
        </button>

        {switchingModalOpen && (
          <ProfileSwitchingModal
            isOpen={switchingModalOpen}
            toggle={toggleSwitchingModal}
            username={username}
            currentProfile={profileType}
            onClose={collapseSideBarWhenToggledModal}
            {...props}
          />
        )}
      </>
    )
  }
)
