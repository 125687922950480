import { makeObservable, override, toJS } from 'mobx'

import * as api from '../api/publisher.api'
import SharedStore from './shared.store'

export class PublisherStore extends SharedStore {
  storeName = 'PublisherStore'
  childApi = api

  @override
  async fetchItem(id, data) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem({
      expand: toJS(this.expands),
      ...data,
    })
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  constructor() {
    super()
    makeObservable(this)

    this.expands = ['user_profile', 'user', 'has_multiple_tier_program']
  }
}

export default new PublisherStore()
