import { GET, PUT, POST } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = '/common/sales_team_member'
const ACCEPT_TERMS = '/accept_terms'

export interface SalesTeamMember {
  fullName: string
  id: number
  salesTeamMemberInvites: SalesTeamMemberInvite
  termsAccepted: boolean
  userProfileId: number
}

interface SalesTeamMemberInvite {
  avatar: string
  id: number
  state: string
  username: string
}

export const fetchItem = (data) => GET<Response<SalesTeamMember>>(`${BASE_URL}`, data)

export const updateItem = (data: any) => PUT<Response<SalesTeamMember>>(BASE_URL, data)

export const acceptTerms = () => POST(`${BASE_URL}${ACCEPT_TERMS}`)
