import { EloQuestionCircleIcon } from '@elo-ui/components/icons/regular'

const HelpPayer = {
  title: I18n.t('react.shared.nav.help'),
  href: `https://support.ablefy.io/hc/${I18n.locale}/categories/115000626265`,
  icon: <EloQuestionCircleIcon size={16} />,
  target: '_blank',
  differentBundle: true,
}

const helpManager = {
  title: I18n.t('react.shared.nav.help'),
  icon: <EloQuestionCircleIcon size={16} />,
  href: '#',
  children: [
    {
      href: '/terms',
      title: I18n.t('react.shared.nav.elopage_tc'),
      visibility: ['business', 'powerSeller'],
      target: '_blank',
    },
    {
      href: '/privacy',
      title: I18n.t('react.shared.nav.elopage_privacy_policy'),
      visibility: ['business', 'powerSeller'],
      target: '_blank',
    },
  ],
}

const footerMenuConfigs = {
  payer: [HelpPayer],
  manager: [helpManager],
}

export default footerMenuConfigs
