import { computed, makeObservable } from 'mobx'
import { getCookies, setCookies } from 'libs/common/cookies'
import { profile } from 'utils/profileHelper.utils'

import * as api from '../api/teamMember.api'
import SharedStore from './shared.store'

export class TeamMemberStore extends SharedStore {
  storeName = 'TeamMemberStore'
  childApi = api // hack to make api visible in abstract store

  constructor() {
    super()

    makeObservable(this)
  }

  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  // store selected seller globally, do not call this function second time, use global config variable
  // also don't use cookies because it's can be resetted from other windows
  setSelectedSellerUsername() {
    const { teamMemberInvites } = this.item
    let tmSelectedSeller = getCookies('tm_selected_seller')
    const allSellers = (teamMemberInvites || []).map((i) => i.username)
    if (!tmSelectedSeller || allSellers.indexOf(tmSelectedSeller) < 0) {
      // prettier-ignore
      ;[tmSelectedSeller] = allSellers
      setCookies('tm_selected_seller', tmSelectedSeller)
    }
    profile.setTmSelectedSeller(tmSelectedSeller)
    return tmSelectedSeller
  }

  @computed get selectedSellerInvite() {
    const { teamMemberInvites } = this.item
    if (!profile.tmSelectedSeller) {
      this.setSelectedSellerUsername()
    }
    return (teamMemberInvites || []).find((i) => i.username === profile.tmSelectedSeller) || {}
  }

  hasPermissions = (key) => {
    const { permissionsKeys } = this.selectedSellerInvite
    return permissionsKeys && permissionsKeys.includes(key)
  }

  @computed get getSellerLegalForm() {
    return this.selectedSellerInvite.powerSeller ? 'powerSeller' : 'business'
  }
}

export default new TeamMemberStore()
