import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
}

const defaultProps = {
  className: 'menu__icon',
  viewbox: '0 0 16 14',
  width: 16,
  height: 14,
}

/**
 * SVG Payout Icon
 */
const IconPayout = (props) => {
  const { className, height, viewBox, width } = props
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <g
        id='Dashboard'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        transform='translate(-5.000000, -299.000000)'
      >
        <path
          className={className}
          d='M19.40625,302.000024 C19.84375,302.000024 20.21875,302.145857 20.53125,302.437523 C20.84375,302.729189 21,303.083355 21,303.50002 L21,311.500001 C21,311.916667 20.84375,312.270833 20.53125,312.562499 C20.21875,312.854164 19.84375,312.999998 19.40625,312.999998 L7,312.999998 C6.4375,312.999998 5.96354151,312.80729 5.578125,312.421874 C5.19270849,312.036458 5,311.562501 5,311.000002 L5,301.000026 C5,300.437528 5.19270849,299.96357 5.578125,299.578155 C5.96354151,299.192739 6.4375,299.000031 7,299.000031 L18.5,299.000031 C18.9166665,299.000031 19.2708335,299.145864 19.5625,299.43753 C19.8541665,299.729196 20,300.083362 20,300.500027 C20,300.645861 19.953125,300.765652 19.859375,300.859402 C19.765625,300.953151 19.6458335,301.000026 19.5,301.000026 L7.5,301.000026 C7.35416651,301.000026 7.234375,301.046901 7.140625,301.140651 C7.046875,301.234401 7,301.354192 7,301.500025 C7,301.645858 7.046875,301.765649 7.140625,301.859399 C7.234375,301.953149 7.35416651,302.000024 7.5,302.000024 L19.40625,302.000024 Z M17.999969,308.500008 C18.2708019,308.500008 18.5051763,308.40105 18.7030923,308.203134 C18.9010084,308.005218 18.9999666,307.770844 18.9999666,307.500011 C18.9999666,307.229178 18.9010084,306.994803 18.7030923,306.796887 C18.5051763,306.598971 18.2708019,306.500013 17.999969,306.500013 C17.7291362,306.500013 17.4947617,306.598971 17.2968457,306.796887 C17.0989296,306.994803 16.9999714,307.229178 16.9999714,307.500011 C16.9999714,307.770844 17.0989296,308.005218 17.2968457,308.203134 C17.4947617,308.40105 17.7291362,308.500008 17.999969,308.500008 Z'
          id='icon'
        />
      </g>
    </svg>
  )
}

IconPayout.displayName = 'IconPayout'
IconPayout.propTypes = propTypes
IconPayout.defaultProps = defaultProps

export default IconPayout
