import { makeObservable } from 'mobx'

import * as api from '../api/payer.api'
import SharedStore from './shared.store'

export class PayerStore extends SharedStore {
  storeName = 'PayerStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions
  getCode = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.getCode()
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  mergeAccounts = (data) => this.childApi.mergeAccounts(data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PayerStore()
