import { GET, POST, PUT } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/common/user_profiles'

interface FetchListBody {
  userProfileId: number
}

export interface VatRegistration extends FetchListBody {
  id: number
  active: boolean
  countryCode: string
  vatNo: string
  createdAt: string
  updatedAt: string
}

type CreateItemBody = Omit<VatRegistration, 'id' | 'createdAt' | 'updatedAt'>

interface FetchItemBody extends FetchListBody {
  id: number
}

type UpdateItemBody = FetchItemBody & CreateItemBody

type VatRegistrationResponse = Response<VatRegistration>

export const fetchList = (data: FetchListBody) =>
  GET<PaginatedResponse<VatRegistration[]>>(`${BASE_URL}/${data.userProfileId}/vat_registrations`, data)

export const fetchItem = (data: FetchItemBody) =>
  GET<VatRegistrationResponse>(`${BASE_URL}/${data.userProfileId}/vat_registrations/${data.id}`)

export const createItem = (data: CreateItemBody) =>
  POST<VatRegistrationResponse>(`${BASE_URL}/${data.userProfileId}/vat_registrations`, data)

export const updateItem = (id: number, data: UpdateItemBody) =>
  PUT<VatRegistrationResponse>(`${BASE_URL}/${data.userProfileId}/vat_registrations/${id}`, data)
