import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  viewbox: PropTypes.string,
  width: PropTypes.number,
  color: PropTypes.string,
}

const defaultProps = {
  className: 'menu__icon',
  viewbox: '0 0 25 19',
  width: 25,
  height: 19,
  color: '#000000',
}

/**
 * SVG SEPA Icon
 */
const IconBurgerMenu = (props) => {
  const { height, viewBox, width, className, color } = props
  return (
    <svg width={width} height={height} viewBox={viewBox} className={className}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-18.000000, -19.000000)'>
        <g transform='translate(18.000000, 19.000000)' fill={color}>
          <rect x='0' y='0' width={width} height='3' rx='1.5' />
          <rect x='0' y='8' width={width} height='3' rx='1.5' />
          <rect x='0' y='16' width={width} height='3' rx='1.5' />
        </g>
      </g>
    </svg>
  )
}

IconBurgerMenu.displayName = 'IconBurgerMenu'
IconBurgerMenu.propTypes = propTypes
IconBurgerMenu.defaultProps = defaultProps

export default IconBurgerMenu
