import React, { useEffect, useState } from 'react'
import EloModal from 'ui/EloModal'
import TokenIdContent from 'shared/components/token-id/TokenIdContent'
import { supportRequest } from 'shared/api/users.api'
import { profile } from 'utils/profileHelper.utils'
import './tokenId.scss'

interface Props {
  toggleIsModalOpen: () => void
  isModalOpen: boolean
}

const TokenIdModal = (props: Props) => {
  const [tokenId, setTokenId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const profileType = profile.profileType

  const fetchSupportRequest = async () => {
    const response = await supportRequest({ profile_type: profileType })
    if (response?.success) {
      setTokenId(response.data.token)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSupportRequest()
  }, [])

  const { toggleIsModalOpen, isModalOpen } = props
  return (
    <EloModal
      isOpen={isModalOpen}
      toggle={toggleIsModalOpen}
      title={I18n.t('react.shared.tokenId.modal_title')}
      className='token-id'
    >
      <TokenIdContent isLoading={isLoading} token={tokenId}></TokenIdContent>
    </EloModal>
  )
}

TokenIdModal.displayName = 'TokenIdModal'

export default TokenIdModal
