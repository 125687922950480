export const SELLER_CARD_CLICK_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'card',
  event_type: 'click',
}

export const SELLER_PAGE_VIEW_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'page',
  event_type: 'pageView',
}

export const SELLER_BUTTON_CLICK_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'button',
  event_type: 'click',
}

export const SELLER_CLICK_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  event_type: 'click',
}

export const SELLER_POPUP_CLICK_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'popup',
  event_type: 'click',
}

export const SELLER_PAGE_LAND_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'page',
  event_type: 'land',
}

export const SELLER_POPUP_VIEW_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'popup',
  event_type: 'view',
}

export const SELLER_CHECKBOX_CLICK_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'checkbox',
  event_type: 'click',
}

export const SELLER_UPLOAD_EVENT = {
  event: 'ga-event',
  domain: 'seller',
  element: 'image',
  event_type: 'upload',
}

export const PRODUCT_TYPE_EVENT = {
  course: 'course',
  service: 'digital',
  download: 'download',
  membership: 'membership',
  event: 'ticket',
  certificate: 'certificate',
  license_key: 'license',
}
