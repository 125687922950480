import { makeObservable } from 'mobx'
import { getLanguages } from 'constants/general.constants'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import * as api from '../api/manager.api'
import SharedStore from './shared.store'
import { MANAGER_ROLES } from '../../admin/constants/manager.constant'

export class ManagerStore extends SharedStore<api.Manager> {
  storeName = 'ManagerStore'
  childApi = api
  getTranslatorLanguages = (): { label: string; value: string }[] => {
    const LANGUAGES = getLanguages()
    const LANGUAGES_OPTIONS = Object.values(LANGUAGES)
    const { role = '', availableTranslations = [] } = this.item || {}
    return role === MANAGER_ROLES.translator
      ? availableTranslations.map((l) => LANGUAGES[snakeCaseToCamelCase(l)])
      : LANGUAGES_OPTIONS
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new ManagerStore()
