import { Nullable } from 'types/helpers'
import { PaginatedResponse, Response } from 'types/responses'
import { GET, PUT } from 'utils/requests.utils'
import { Seller } from './sellers.api'

const BASE_URL = '/admin/products'

export interface Product {
  id: number
  slug: string
  name: string
  form: string
  free: boolean
  private: boolean
  checkoutHtml: string
  checkoutHtmlFooter: string
  accessPassword: string
  protectionUrl: string
  shortDescription: string
  invoiceShortDescription: boolean
  courseMenuUnfold: boolean
  description: string
  position: number
  cancellationTermId?: Nullable<number>
  b2bCancellationTermId?: Nullable<number>
  terms: string
  published: boolean
  active: boolean
  show_cover: boolean
  cancelLink: string
  paymentPageTemplateId: Nullable<number>
  showProductsSection: boolean
  sellerId: number
  courseThemeId: Nullable<number>
  sellMultiple: boolean
  limitPerOrder: number
  displayPrice: number
  displayOldPrice: number
  displayCurrencyId: number
  reviewState: string
  performancePeriodText: string
  performancePeriodReviewType?: string
  performancePeriodReviewTypeChangeCount?: string
  seller?: Seller
}

type ProductResponse = Response<Product>

type ProductId = string | number

interface UpdateItemBody {
  vatSettingId?: number
  reviewState?: string
}

export const fetchList = (data) => GET<PaginatedResponse<Product[]>>(BASE_URL, data)

export const fetchItem = (id: ProductId, data) => GET<ProductResponse>(`${BASE_URL}/${id}`, data)

export const updateItem = (id: ProductId, data: UpdateItemBody) => PUT<ProductResponse>(`${BASE_URL}/${id}`, data)

export const fetchCount = (data: { reviewState?: string }) =>
  GET<Response<{ count: number }>>(`${BASE_URL}/count`, data)
