import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { setCookies } from 'libs/common/cookies'

import { ELOPAGE_PROFILE_CABINETS } from 'constants/general.constants'

import { Ellipsis } from '@elo-kit/components/ellipsis/Ellipsis'
import { TooltipMenu } from '@elo-kit/components/elo-ui/tooltip-menu/TooltipMenu'

import Avatar from './Avatar'

const Seller = ({ seller }) => (
  <Fragment>
    <Avatar img={seller.avatarUrl} />
    <div className='account-widget-container__t-seller-name'>{seller.username}</div>
  </Fragment>
)

@observer
class AccountWidget extends Component {
  switchToPayerSeller = (username) => {
    setCookies('cabinet_profile_type', ELOPAGE_PROFILE_CABINETS.payer).then(
      () => (window.location.href = `/${ELOPAGE_PROFILE_CABINETS.payer}/s/${username}`)
    )
  }

  changeAccount = () => {
    const { handleAccountClick, active } = this.props
    !active && handleAccountClick()
  }

  getMenuItems = () => {
    const { sellersList } = this.props
    return sellersList.map((seller, index) => ({
      id: `${seller.username}-${index}`,
      show: true,
      action: () => this.switchToPayerSeller(seller.username),
      content: <Seller seller={seller} />,
    }))
  }

  render() {
    const { icon, accountName, profileTypeText, sellersList, active, id, disabled } = this.props
    const containerWidgetClasses = classNames('account-widget-container', {
      'account-widget-container__tooltip': active,
    })
    const containerClasses = classNames('account-widget', {
      'account-widget--active': active,
      'account-widget--disabled': disabled,
    })
    const clickableAreaClasses = classNames('account-widget__clickable', {
      'account-widget__clickable--short': sellersList,
    })

    return (
      <div className={containerWidgetClasses}>
        <div className={containerClasses}>
          <div className='account-widget__icon'>{typeof icon === 'string' ? <i className={icon} /> : icon}</div>
          <div className='account-widget__details'>
            <Ellipsis maxLength={13} Wrapper={({ children }) => <div className='account-widget__name'>{children}</div>}>
              {accountName}
            </Ellipsis>
            <div className='account-widget__type'>{profileTypeText}</div>
          </div>
        </div>
        <div
          className={clickableAreaClasses}
          onClick={this.changeAccount}
          onKeyPress={this.changeAccount}
          role='button'
          tabIndex={0}
        />
        {sellersList && (
          <TooltipMenu
            shouldFocusOnFirstItem
            id={`tooltipm-${id}`}
            menuItems={this.getMenuItems()}
            popoverClass='account-widget-container__sellers'
            role='button'
            tabIndex={0}
          />
        )}
      </div>
    )
  }
}

export default AccountWidget
