import { isProduction } from 'utils/env.utils'
import { holdProdPlanIds, holdStagingPlanIds, trialStagingPlanIds, trialProdPlanIds } from 'constants/plan'

interface SellerData {
  supportFirstName: string
  supportLastName: string
  supportEmail: string
  supportPhone: string
}

export const isTrialPeriodEnd = (planId: number) =>
  isProduction() ? holdProdPlanIds.includes(planId) : holdStagingPlanIds.includes(planId)

export const isOnTrialPeriod = (planId: number) =>
  isProduction() ? trialProdPlanIds.includes(planId) : trialStagingPlanIds.includes(planId)

export const isSupportInformationCompleted = (sellerData: SellerData): boolean => {
  const { supportFirstName, supportLastName, supportEmail } = sellerData || {}

  return [supportFirstName, supportLastName, supportEmail].every(Boolean)
}
