import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/resellerRequests.api'

export class ResellerRequestsStore extends SharedStore<api.ResellerRequest | api.ResellerRequestCount> {
  storeName = 'ResellerRequestsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable count = 0

  @action
  setCount = (count) => (this.count = count)

  approve = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.approve(id, data)
    this.toggleLoading(false)
    return resp
  }

  reject = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.reject(id, data)
    this.toggleLoading(false)
    return resp
  }

  forceToNormalSeller = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.forceToNormalSeller(id, data)
    this.toggleLoading(false)
    return resp
  }

  forceToPowerSeller = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.forceToPowerSeller(id, data)
    this.toggleLoading(false)
    return resp
  }

  @action
  fetchCount = async () => {
    const resp = await this.childApi.fetchCount()
    this.setCount(resp?.data?.count)

    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export const resellerRequestsStore = new ResellerRequestsStore()
