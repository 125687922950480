import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classNames from 'classnames'

const propTypes = {
  img: PropTypes.string,
}

@observer
class Avatar extends Component {
  render() {
    const { img, className } = this.props
    const avatarClasses = classNames('elo-avatar', className)

    return <div className={avatarClasses}>{img && <img src={img} className='elo-avatar__img' />}</div>
  }
}

Avatar.displayName = 'Avatar'
Avatar.propTypes = propTypes

export default Avatar
