import { GET } from 'utils/requests.utils'
import { Nullable } from 'types/helpers'
import { Response } from 'types/responses'
const BASE_URL = '/common/manager'
export interface Manager {
  id: number
  availableTranslations: string[]
  managerPermissions: string[]
  role: string
  wistiaProjectId: Nullable<unknown>
  wistiaProjectPass: string
}
export const fetchItem = () => GET<Response<Manager>>(BASE_URL)

export default fetchItem
