const socialMenuConfigs = [
  {
    id: 'facebook',
    href: 'https://m.facebook.com/ablefy/',
    icon: 'fab fa-facebook-f',
  },
  {
    id: 'linkedin',
    href: 'https://www.linkedin.com/company/ablefy',
    icon: 'fab fa-linkedin-in',
  },
  {
    id: 'instagram',
    href: 'https://instagram.com/able.fy',
    icon: 'fab fa-instagram',
  },
]

export default socialMenuConfigs
