import { GET, POST } from 'utils/requests.utils'
import { HTTPResponse, PaginatedResponse, Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/admin/reseller_requests'

export interface ResellerRequest {
  id: number
  state: string
  form: string
  requestMsg: Nullable<string>
  responseMsg: Nullable<string>
  sellerId: number
  createdAt: string
  updatedAt: string
}

export interface ResellerRequestCount {
  count: number
}

export const fetchList = (data) => GET<PaginatedResponse<ResellerRequest[]>>(BASE_URL, data)

export const fetchItem = (id: string | number, data) => GET<Response<ResellerRequest>>(`${BASE_URL}/${id}`, data)

export const approve = (id: string | number, data) => POST<HTTPResponse>(`${BASE_URL}/${id}/approve`, data)

export const reject = (id: string | number, data) => POST<HTTPResponse>(`${BASE_URL}/${id}/reject`, data)

export const forceToNormalSeller = (id: string | number, data) =>
  POST<HTTPResponse>(`${BASE_URL}/${id}/force_to_normal_seller`, data)

export const forceToPowerSeller = (id: string | number, data) =>
  POST<HTTPResponse>(`${BASE_URL}/${id}/force_to_power_seller`, data)

export const fetchCount = () => GET<Response<ResellerRequestCount>>(`${BASE_URL}/count`, { state: 0 })
