import React, { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars-2'
import classNames from 'classnames'
import camelcase from 'camelcase'
import { notify } from 'libs/common/notify'

import { STATUSES, COPYRIGHT } from 'constants/general.constants'
import { ACTIVE_PROFILES, PROFILES_ROOT_PATH } from 'constants/profile.constants'
import { NOTIFIC_SETTINGS_PERMISSION_KEY } from 'constants/notifications.constants'
import { SELLER_BUTTON_CLICK_EVENT } from 'constants/GTMDataLayer.constants'

import { createId } from '@elo-kit/utils/general.utils'
import { filteredMenuItemsList } from 'utils/sideMenu.utils'
import { getProfileFullName } from 'utils/user.utils'
import { isTrialPeriodEnd } from 'utils/seller.utils'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'

import IconBurgerMenu from 'ui/icons/IconBurgerMenu'
import TokenIdModal from 'shared/components/token-id/TokenIdModal'
import { EloButton } from '@elo-ui/components/elo-button/'
import { EloListIcon, EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloLink } from '@elo-ui/components/elo-link'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import { MenuItem } from './MenuItem'
import { ProfileSwitcher } from './profile-switching/ProfileSwitcher'
import mainMenuConfigs from './menu-configs/mainMenuConfigs'
import managerMenuConfigs from './menu-configs/managerMenuConfigs'
import settingMenuConfigs from './menu-configs/settingMenuConfigs'
import footerMenuConfigs from './menu-configs/footerMenuConfigs'
import socialMenuConfigs from './menu-configs/socialMenuConfigs'

import elopageLightLogo from './assets/elopage-light.svg'
import eLightLogo from './assets/e.svg'
import eDarkLogo from './assets/e-dark.svg'

import { MANAGER_ROLES } from '../../../admin/constants/manager.constant'

import './side-menu.scss'

const PROFILE_TYPES_ATTRIBUTES = {
  [ACTIVE_PROFILES.payer]: {
    sideMenuRootLink: undefined,
  },
  [ACTIVE_PROFILES.seller]: {
    sideMenuRootLink: '/cabinet',
  },
  [ACTIVE_PROFILES.manager]: {
    sideMenuRootLink: undefined,
  },
  [ACTIVE_PROFILES.teamMember]: {
    sideMenuRootLink: undefined,
  },
  [ACTIVE_PROFILES.salesTeamMember]: {
    sideMenuRootLink: undefined,
  },
  [ACTIVE_PROFILES.publisher]: {
    sideMenuRootLink: undefined,
  },
  [ACTIVE_PROFILES.eloPublisher]: {
    sideMenuRootLink: undefined,
  },
}

const SIDE_MENU_ROOT_LINK = '/'
const SUCCESS_STATUS_CODE = 202

interface SideMenuProps {
  profileType: string
  domainLogo?: string
  userStore: any
  profileStore: any
  sellerStore: any
  publisherStore: any
  eloPublisherStore: any
  salesTeamsStore: any
  teamMemberStore: any
  salesTeamMemberStore: any
  paymentSettingStore: any
  payerStore: any
  clearCacheStore: any
  hideBurgerMenu?: boolean
  menuModel: {
    toggleMenu: () => void
  }
  collapsedSidebar: {
    isCollapsed: boolean
    collapseSideBar: () => void
    isToggled: boolean
    toggleSideBar: () => void
    isHovered: boolean
    hoverSideBar: (boolean) => void
    isLogoCollapsed: boolean
    showCollapsedLogo: () => boolean
    toggleSettings: () => boolean
  }
  currenciesStore: any
  pathname: string
  LinkComponent: React.FC
}

interface StaticComponents {
  Logo?: React.FC<{
    domainLogo: string
    rootLink: string
    collapseSideBar: () => void
    toggleSideBar: () => void
    isLogoCollapsed: boolean
    toggleCollapsedClasses: () => void
  }>
  Section?: React.FC<{ children: React.ReactNode; className?: string }>
  Copyright?: React.FC<{
    rootLink: string
    isPayer: boolean
    isLogoCollapsed?: boolean
  }>
  Socials?: React.FC<Record<string, never>>
}

export const SideMenu: React.FC<SideMenuProps> & StaticComponents = inject(
  'menuModel',
  'collapsedSidebar'
)(
  observer(
    ({
      profileType,
      domainLogo,
      userStore,
      userStore: { item: user },
      profileStore: { item: profile },
      profileStore,
      sellerStore,
      publisherStore,
      eloPublisherStore,
      teamMemberStore,
      salesTeamMemberStore,
      paymentSettingStore,
      payerStore,
      salesTeamsStore,
      clearCacheStore,
      hideBurgerMenu,
      menuModel: { toggleMenu },
      collapsedSidebar: {
        isCollapsed,
        collapseSideBar,
        isToggled,
        toggleSideBar,
        isHovered,
        hoverSideBar,
        isLogoCollapsed,
        showCollapsedLogo,
        toggleSettings,
      },
      currenciesStore,
      pathname,
      LinkComponent,
    }) => {
      const history = useHistory()

      const [isTokenIdModalOpen, setIsTokenIdModalOpen] = useState(false)
      const [isOwnSidebarOpen, setIsOwnSidebarOpen] = useState(false)
      const [activeMenuItem, setActiveMenuItem] = useState('')
      const [isHoveredLocal, setIsHoveredLocal] = useState(false)
      const [disabledClearCache, setDisabledClearCache] = useState(false)

      useEffect(() => {
        hoverSideBar(isHoveredLocal)
        showCollapsedLogo()
      }, [isToggled, isHoveredLocal, isCollapsed])

      useEffect(() => {
        if (isPayer && isCollapsed && isToggled && !isHoveredLocal && !isHovered) {
          hoverSideBar(true)
          showCollapsedLogo()
        }
      }, [])

      const { item: seller } = sellerStore || {}
      const { item: salesTeamMember } = salesTeamMemberStore || {}
      const { data: paymentSettingStoreData } = paymentSettingStore || {}
      const { active, bankWire } = sellerStore.item.activePaymentSetting?.apiSettings?.elopageConnect || {}

      const isMangoPayAndMangoVirtualIbanActive =
        paymentSettingStoreData?.apiSettings?.mangoPay?.active &&
        paymentSettingStoreData?.apiSettings?.mangoPay?.virtualIban
      const isECActiveAndECVirtualIbanActive =
        (paymentSettingStoreData?.apiSettings?.elopageConnect?.active &&
          paymentSettingStoreData?.apiSettings?.elopageConnect?.bankWire) ||
        (active && bankWire && sellerStore.item.powerSeller)

      const additionalItemOnSalesMemberMenu = salesTeamMember?.salesTeamMemberInvites?.some(
        (el) => el.state === 'accepted'
      )

      const [isTeamMember, isManager, isPayer, isSeller] = [
        ACTIVE_PROFILES.teamMember,
        ACTIVE_PROFILES.manager,
        ACTIVE_PROFILES.payer,
        ACTIVE_PROFILES.seller,
      ].map((e) => e === profileType)

      const profileTypeEnum = Object.keys(ACTIVE_PROFILES).find((type) => profileType === ACTIVE_PROFILES[type])
      const profileTypeAttributes = PROFILE_TYPES_ATTRIBUTES[ACTIVE_PROFILES[profileTypeEnum]]

      const toRedirectPage = (value = '') =>
        history.push(`${SIDE_MENU_ROOT_LINK}${PROFILES_ROOT_PATH[profileType]}${value}`)

      const toggleTokenIdModal = () => setIsTokenIdModalOpen(!isTokenIdModalOpen)
      const toggleOwnSidebar = () => {
        if (!isOwnSidebarOpen) {
          toRedirectPage('/profile/edit')
          if (isCollapsed) {
            collapseSideBar()
            toggleSideBar()
            toggleSettings()
          }
        } else {
          toRedirectPage()
        }
        setIsOwnSidebarOpen(!isOwnSidebarOpen)
      }
      const hoverSideBarLocal = () => setIsHoveredLocal(!isHoveredLocal)

      const toggleCollapsedClasses = () => {
        const sideBar = document.querySelector('.side-menu')
        sideBar.classList.toggle('collapsed')
      }

      const hoverOn = () => {
        if (isCollapsed && isToggled) {
          const sideBar = document.querySelector('.side-menu')
          sideBar.classList.remove('collapsed')
          hoverSideBarLocal()
        }
      }

      const hoverOff = () => {
        if (isCollapsed && isToggled) {
          const sideBar = document.querySelector('.side-menu')
          sideBar.classList.add('collapsed')
          hoverSideBarLocal()
        }
      }

      const { showCashout, showPayout, powerSeller, username: sellerUsername } = seller || {}

      const showTeamTabs = salesTeamsStore?.list[0]?.state === 'activated'

      const { managerPermissions, role, fullName, userProfile } = profile || {}

      const { selectedSellerInvite = {} } = profileStore || {}

      const invitePermissions = (selectedSellerInvite || {}).permissionsKeys || []
      const teamMemberRolePermissions =
        selectedSellerInvite.state === STATUSES.approved ? [...invitePermissions, NOTIFIC_SETTINGS_PERMISSION_KEY] : []

      const legalForm = powerSeller ? 'powerSeller' : 'business'
      const profileName = isSeller ? getProfileFullName(userProfile) : fullName
      const username = profileName || sellerUsername || user.email
      const withPermissions =
        (isTeamMember && selectedSellerInvite) ||
        (isManager &&
          [MANAGER_ROLES.translator, MANAGER_ROLES.payerSupport, MANAGER_ROLES.accountant].indexOf(role) !== -1)
      const permissions = withPermissions ? managerPermissions || teamMemberRolePermissions : []
      const menuItems = isManager ? managerMenuConfigs : mainMenuConfigs[profileType]
      const setMenuItems = settingMenuConfigs[profileType]
      const setFooterItems = footerMenuConfigs[profileType]
      const expiredTrialMenuItems =
        isSeller && isTrialPeriodEnd(sellerStore.item.planId)
          ? [menuItems[0], menuItems[menuItems.length - 1]]
          : menuItems

      const mainMenuItems = filteredMenuItemsList({
        menuItems: expiredTrialMenuItems,
        withPermissions,
        permissions,
      })
      const settingsMenuItems = filteredMenuItemsList({
        menuItems: setMenuItems,
        withPermissions,
        permissions,
      })
      const footerMenuItems = filteredMenuItemsList({
        menuItems: setFooterItems,
        withPermissions,
        permissions,
      })

      useEffect(() => {
        const isPathSettingsMenuItems = settingsMenuItems?.some(
          (item) =>
            item.children?.some((children) => children.href === pathname || children.subItems?.includes(pathname))
        )

        if (isPathSettingsMenuItems) {
          setIsOwnSidebarOpen(true)
          if (isCollapsed) {
            const sideBar = document.querySelector('.side-menu')
            sideBar.classList.remove('collapsed')
            collapseSideBar()
            toggleSideBar()
          }
        } else if (isOwnSidebarOpen) {
          setIsOwnSidebarOpen(!isOwnSidebarOpen)
        }
      }, [history.location.pathname])

      const sellerPresent = !isTeamMember || selectedSellerInvite
      const rootLink = profileTypeAttributes?.sideMenuRootLink ?? SIDE_MENU_ROOT_LINK

      const sideMenuClasses = classNames('side-menu side-menu--left', 'side-menu--blue')

      const headerClasses = classNames('side-menu__header', {
        'side-menu__header--collapsed': isCollapsed && isHovered,
        'custom-logo': domainLogo,
      })

      const clearCacheClasses = classNames('side-menu__footer--cache-button', {
        'side-menu__footer--cache-button-disabled': disabledClearCache,
      })

      const pushToDataLayer = (isSuccess = false) => {
        addToDataLayer({
          ...SELLER_BUTTON_CLICK_EVENT,
          object: 'profile',
          event_name: isSuccess ? 'profile_clearCacheSuccess' : 'profile_clearCache',
        })
      }

      const handleClearCache = async () => {
        setDisabledClearCache(true)
        pushToDataLayer()
        const response = await clearCacheStore.clearCache(seller.id)
        if (response.status === SUCCESS_STATUS_CODE) {
          pushToDataLayer(true)
          notify('success', I18n.t('react.shared.nav.clear_cache_notification'))
        }

        setDisabledClearCache(false)
      }

      const itemActions = {
        toggleTokenIdModal: toggleTokenIdModal,
        toggleOwnSidebar: toggleOwnSidebar,
      }

      const handleCloseOpen = (value) => {
        setActiveMenuItem((prevValue) => (prevValue !== value ? value : ''))
      }

      if (!user.id || !profile.id || (isSeller && !seller.id)) {
        return null
      }

      return (
        <>
          {!hideBurgerMenu && (
            <div id='burger-menu' onClick={toggleMenu}>
              <IconBurgerMenu />
            </div>
          )}

          <div className={sideMenuClasses}>
            {!isOwnSidebarOpen && (
              <>
                <div className={headerClasses}>
                  <SideMenu.Logo
                    {...{
                      domainLogo,
                      rootLink,
                      collapseSideBar,
                      toggleSideBar,
                      showCollapsedLogo,
                      toggleCollapsedClasses,
                      isLogoCollapsed,
                    }}
                  />
                </div>
                <Scrollbars
                  autoHide
                  universal
                  autoHideDuration={100}
                  autoHideTimeout={500}
                  className='side-menu__scroll'
                  onMouseEnter={hoverOn}
                  onMouseLeave={hoverOff}
                >
                  <div className='side-menu__content-wrapper'>
                    <div className='side-menu__content'>
                      {isManager && (
                        <ProfileSwitcher
                          avatar={(user.avatar || {}).s200}
                          {...{
                            profileType,
                            username,
                            userStore: userStore,
                            sellerStore,
                            publisherStore,
                            eloPublisherStore,
                            teamMemberStore,
                            salesTeamMemberStore,
                            payerStore,
                            isCollapsed,
                            isHovered,
                            hoverOff,
                          }}
                        />
                      )}
                      <SideMenu.Section>
                        <>
                          {mainMenuItems.map((item, index) => {
                            if (!sellerPresent && index > 0) {
                              return null
                            }

                            const menuItemProps = {
                              item,
                              legalForm,
                              profileType,
                              showCashout,
                              showPayout,
                              additionalItemOnSalesMemberMenu,
                              showTeamTabs,
                              showSalesTeam: !showTeamTabs,
                              currenciesStore,
                              pathname,
                              itemActions,
                              handleCloseOpen,
                              activeMenuItem,
                              setActiveMenuItem,
                              isECActiveAndECVirtualIbanActive,
                              isMangoPayAndMangoVirtualIbanActive,
                            }

                            return (
                              <MenuItem
                                key={createId(item.title, index)}
                                {...menuItemProps}
                                LinkComponent={LinkComponent}
                              />
                            )
                          })}

                          {footerMenuItems &&
                            footerMenuItems.map((item, index) => (
                              <MenuItem
                                key={createId(item.title, index)}
                                LinkComponent={LinkComponent}
                                {...{
                                  item,
                                  legalForm,
                                  profileType,
                                  currenciesStore,
                                  itemActions,
                                  pathname,
                                  hoverOff,
                                  handleCloseOpen,
                                  activeMenuItem,
                                  setActiveMenuItem,
                                }}
                              />
                            ))}
                        </>
                      </SideMenu.Section>
                    </div>
                  </div>
                </Scrollbars>
                <div className='side-menu__footer'>
                  <SideMenu.Copyright
                    {...{
                      rootLink,
                      isPayer,
                      isCollapsed,
                      isToggled,
                      isHovered,
                      isHoveredLocal,
                      isLogoCollapsed,
                    }}
                  />
                </div>
              </>
            )}

            {isOwnSidebarOpen && (
              <Scrollbars autoHide universal autoHideDuration={100} autoHideTimeout={500} className='side-menu__scroll'>
                <div className='side-menu__content-wrapper'>
                  <div className='side-menu__content'>
                    <div className='side-menu__own-header'>
                      <i className='far fa-angle-left' onClick={toggleOwnSidebar} />
                      <div className='side-menu__own-title'>{I18n.t('react.shared.nav.settings')}</div>
                      <div className='side-menu__close-settings-button' onClick={toggleMenu}>
                        <i className='fas fa-times-circle' />
                      </div>
                    </div>
                    <SideMenu.Section className='side-menu__own'>
                      {settingsMenuItems.map((item, index) => {
                        if (!sellerPresent && index > 0) {
                          return null
                        }

                        const menuItemProps = {
                          item,
                          legalForm,
                          profileType,
                          currenciesStore,
                          pathname,
                          handleCloseOpen,
                          activeMenuItem,
                          setActiveMenuItem,
                        }

                        return (
                          <MenuItem
                            key={createId(item.title, index)}
                            userId={user.id}
                            {...menuItemProps}
                            {...{ itemActions }}
                            LinkComponent={LinkComponent}
                          />
                        )
                      })}
                    </SideMenu.Section>
                    <div className='side-menu__footer'>
                      {isSeller && seller.id && (
                        <div className='side-menu__footer--cache'>
                          <EloLink
                            as='span'
                            className={clearCacheClasses}
                            disabled={disabledClearCache}
                            onClick={handleClearCache}
                          >
                            {I18n.t('react.shared.nav.clear_cache')}
                          </EloLink>
                          <EloTooltip content={I18n.t('react.shared.nav.clear_cache_tooltip')}>
                            <EloInfoIcon />
                          </EloTooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Scrollbars>
            )}
          </div>

          {isTokenIdModalOpen && (
            <TokenIdModal toggleIsModalOpen={toggleTokenIdModal} isModalOpen={isTokenIdModalOpen} />
          )}
        </>
      )
    }
  )
)

SideMenu.Logo = ({ domainLogo, rootLink, collapseSideBar, toggleSideBar, isLogoCollapsed, toggleCollapsedClasses }) => {
  const eloLogo = isLogoCollapsed ? eLightLogo : elopageLightLogo

  const sideMenuLogoClasses = classNames('side-menu__logo', {
    'side-menu__logo--collapsed': isLogoCollapsed,
  })

  return (
    <div className='side-menu__logo-container'>
      <EloButton
        size='medium'
        leftIcon={<EloListIcon />}
        onClick={(e) => {
          e.preventDefault()
          collapseSideBar()
          toggleSideBar()
          toggleCollapsedClasses()
        }}
      />
      <a href={rootLink} className={sideMenuLogoClasses}>
        <img src={domainLogo || eloLogo} alt='img' />
      </a>
    </div>
  )
}

SideMenu.Copyright = ({ rootLink, isPayer, isLogoCollapsed }) => {
  const copyright = isLogoCollapsed ? <img src={eDarkLogo} alt='img' /> : COPYRIGHT
  return (
    <div className='side-menu__copyright'>
      {isPayer ? (
        <a className='footer-link' href={rootLink}>
          {copyright}
        </a>
      ) : (
        <span>{copyright}</span>
      )}
    </div>
  )
}

SideMenu.Section = ({ children, className }) => (
  <>
    <ul className={className}>{children}</ul>
  </>
)

SideMenu.Socials = () => (
  <div className='side-menu__socials'>
    {socialMenuConfigs.map(({ href, icon, id }, index) => (
      <a id={camelcase(`sideMenu ${id}`)} className='item' href={href} key={createId(id, index)} target='blank'>
        <i className={`${icon} menu__icon--social menu__icon--social--${id}`} />
      </a>
    ))}
  </div>
)
