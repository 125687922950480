import { GET, PUT, POST } from 'utils/requests.utils'

const BASE_URL = '/common/payer'

export const fetchItem = () => GET(BASE_URL)

export const updateItem = (data) => PUT(BASE_URL, data)

export const getCode = () => POST(`${BASE_URL}/get_code`)

export const mergeAccounts = (data) => POST(`${BASE_URL}/merge_accounts`, data)
