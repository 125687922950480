import React, { Component } from 'react'
import { action, observable, makeObservable } from 'mobx'
import { observer, Provider } from 'mobx-react'
import classNames from 'classnames'

import { SideMenu } from './SideMenu'

import './page-wrapper.scss'

class MenuModel {
  @observable menuOpen = false

  @action toggleMenu = () => {
    this.menuOpen = !this.menuOpen
  }

  constructor() {
    makeObservable(this)
  }
}

class CollapsedSidebar {
  @observable isCollapsed = false
  @observable isToggled = false
  @observable isHovered = false
  @observable isLogoCollapsed = false
  @observable isSettingsToggled = false

  @action showCollapsedLogo = () => {
    if (this.isCollapsed && this.isToggled && this.isSettingsToggled) {
      this.isLogoCollapsed = this.isHovered
    } else if (this.isCollapsed && this.isToggled) {
      this.isLogoCollapsed = !this.isHovered
    } else {
      this.isLogoCollapsed = false
    }
  }

  @action collapseSideBar = () => {
    this.isCollapsed = !this.isCollapsed
  }

  @action toggleSideBar = () => {
    this.isToggled = !this.isToggled
  }

  @action hoverSideBar = (val) => {
    this.isHovered = val
  }

  @action toggleSettings = () => {
    this.isSettingsToggled = !this.isSettingsToggled
  }

  constructor() {
    makeObservable(this)
  }
}

@observer
export class Page extends Component {
  static SideMenu = (props) => <SideMenu {...props} />
  static Content = ({ classNames = '', children }) => <div className={classNames || 'content-page'}>{children}</div>

  @observable menuModel = new MenuModel()
  @observable collapsedSidebar = new CollapsedSidebar()

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  render() {
    const {
      props: { children },
      menuModel,
      collapsedSidebar,
    } = this

    const pageWrapperClasses = classNames({
      enlarged: menuModel.menuOpen,
      'with-collapsed-sidebar': collapsedSidebar.isCollapsed,
    })

    return (
      <Provider menuModel={this.menuModel} collapsedSidebar={this.collapsedSidebar}>
        <div id='page-wrapper' className={pageWrapperClasses}>
          {children}
        </div>
      </Provider>
    )
  }
}
