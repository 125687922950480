import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

import { AUTOCOMPLETE_OFF } from 'constants/forms.constants'

const propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  lowercased: PropTypes.bool,
  required: PropTypes.bool,
}

const defaultProps = {
  autocomplete: AUTOCOMPLETE_OFF,
  disabled: false,
  lowercased: false,
}

@observer
class FPartialTextField extends Component {
  static contextType = I18nContext

  render() {
    const {
      field,
      form: { touched, errors },
      className,
      label,
      predefinedText,
      placeholder,
      autocomplete,
      disabled,
      tooltipId,
      lowercased,
      required,
      ...props
    } = this.props

    const isInvalid = touched[field.name] && errors[field.name]

    const fieldClassNames = classNames(
      'field partial-text-field',
      {
        'field--disabled': disabled,
        'field--required': required,
      },
      className
    )

    const fieldControlClassNames = classNames('field__control partial-text-field__control', {
      'partial-text-field__control--error': isInvalid,
      'partial-text-field__control--lowercased': lowercased,
    })

    const predifinedFieldClassNames = classNames(
      'field__control partial-text-field__control partial-text-field__predefined-text',
      { 'partial-text-field__control--error': isInvalid }
    )

    return (
      <div className={fieldClassNames}>
        {label && (
          <label className='field__label' htmlFor={field.name}>
            <span>{label}</span>
            {tooltipId && <InfoTooltip id={`${field.name}_popover`} body={tooltipId} />}
          </label>
        )}
        <div>
          {isInvalid && (
            <div className='field__error'>
              {errors[field.name]}
              <i className='fas fa-exclamation-circle' />
            </div>
          )}

          <div className={'partial-text-field__text-container'}>
            <span className={predifinedFieldClassNames}>{predefinedText}</span>

            <input
              id={field.name}
              type='text'
              className={fieldControlClassNames}
              placeholder={placeholder}
              autoComplete={autocomplete}
              {...field}
              {...props}
            />
          </div>
        </div>
      </div>
    )
  }
}

FPartialTextField.displayName = 'FPartialTextField'
FPartialTextField.propTypes = propTypes
FPartialTextField.defaultProps = defaultProps

export default FPartialTextField
