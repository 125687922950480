import React, { useState, Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

interface SubItem {
  title: string
  href: string
  key: string
}

interface Item {
  title: string
  icon: string
  href?: string
  children?: SubItem[]
}

interface CountingItemProps {
  item: {
    icon: string
    title: string
  }
  count: number[] | number
  data: {
    currenciesStore: any
    notificationsStore?: any
    profileType?: string
  }
  onClick?: () => void
  children?: React.ReactNode
  hoverOff?: () => void
  isLoaded?: (arg1: Item) => boolean
}

export const CountingItem: React.FC<CountingItemProps> = observer(
  ({ item, item: { icon, title }, count, data, onClick, children, isLoaded }) => {
    const { notificationsStore } = data || {}
    const [opened, setOpened] = useState<boolean>(false)

    useEffect(() => {
      if (notificationsStore) {
        notificationsStore.setExpands([
          'notific_type',
          'notificable.lesson',
          'notificable.lesson.product',
          'sender',
          'seller',
          'sender.user',
          'sender.user_profile',
        ])
        notificationsStore.handleScopeChange('platform', 'web')
        return () => notificationsStore.resetList()
      }
    }, [])

    useEffect(() => {
      if (notificationsStore && opened) notificationsStore.fetchList()
    }, [opened])

    const toggleOpened = () => setOpened(!opened)

    const isCountArray = Array.isArray(count)
    const countPresent = isCountArray ? count.filter(Boolean).length > 0 : count > 0
    let label

    if (isCountArray) {
      label = countPresent ? count.join(' ') : '0 0'
    } else {
      label = count || 0
    }

    const notificsClasses = classNames('notifics-count', {
      'has-unread': countPresent,
      multiple: isCountArray,
    })
    const notificsParentClasses = classNames('menu-item--notific icon', {
      active: isLoaded(item),
    })

    return (
      <Fragment>
        <a className={notificsParentClasses} href='#' onClick={onClick || toggleOpened}>
          <div className='category-container'>
            <i className={icon} />
            <span className='menu-title'>{title}</span>
            <span className={notificsClasses}>{label}</span>
          </div>
        </a>

        {children}
      </Fragment>
    )
  }
)
