import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

import './checkbox-field.scss'
import { addTestId } from 'utils/e2e.utils'

const propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  customHandleRequired: PropTypes.bool,
  labelClasses: PropTypes.string,
  testId: PropTypes.string,
}

const defaultProps = {
  disabled: false,
  customHandleRequired: false,
}

const FCheckboxField = (props) => {
  const {
    className,
    customHandleRequired,
    disabled,
    field,
    form,
    label,
    tooltipId,
    tooltipTitle,
    required,
    labelClasses,
    tooltipCheck, // to avoid throwing into input props
    testId,
    ...restProps
  } = props

  const { name, value } = field
  const { touched, errors } = form

  const isInvalid = touched[name] && errors[name]
  const fieldClassNames = classNames(
    'field checkbox-field',
    {
      'field--disabled': disabled,
      'field--required': required,
    },
    className
  )

  const labelClassNames = classNames(
    'checkbox-field__label',
    {
      'checkbox-field__label--active': value,
    },
    labelClasses
  )

  const customCheckClassNames = classNames('custom-check-mark', {
    'custom-check-mark--required': customHandleRequired && isInvalid,
  })

  const tooltipTitles = {
    recurring: I18n.t('react.cabinet.help_icon.recurring.title'),
    upsells: I18n.t('react.cabinet.help_icon.recurring.title'),
    additional_fees: I18n.t('react.cabinet.help_icon.recurring.title'),
  }

  const tooltipContent = {
    recurring: I18n.t('react.cabinet.help_icon.recurring.content'),
    upsells: I18n.t('react.cabinet.help_icon.upsells.content'),
    additional_fees: I18n.t('react.cabinet.help_icon.additional_fees.content'),
    starttls_auto: I18n.t('react.cabinet.help_icon.starttls_auto.content'),
    liable_for_vat: I18n.t('react.cabinet.help_icon.liable_for_vat.content'),
    accept_mangopay_terms: I18n.t('react.cabinet.help_icon.accept_mangopay_terms.content'),
    lifetime_access: I18n.t('react.cabinet.help_icon.lifetime_access.content'),
    posts_allowed: I18n.t('react.cabinet.help_icon.posts_allowed.content'),
    post_moderation: I18n.t('react.cabinet.help_icon.post_moderation.content'),
  }

  return (
    <div className={fieldClassNames}>
      <label className='field__control checkbox-field__control'>
        <input
          id={name}
          type='checkbox'
          disabled={disabled}
          checked={!!value}
          {...addTestId(testId ?? field.name)}
          {...field}
          {...restProps}
        />

        <span className={customCheckClassNames}>
          <i className='boom' />
        </span>

        <label className={labelClassNames}>
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {tooltipId && (
            <InfoTooltip
              id={`${tooltipId}_popover`}
              title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
              body={tooltipContent[tooltipId]}
            />
          )}

          {!customHandleRequired && isInvalid && (
            <div className='field__error'>
              {errors[name]}
              <i className='fas fa-exclamation-circle' />
            </div>
          )}
        </label>
      </label>
    </div>
  )
}

FCheckboxField.displayName = 'FCheckboxField'
FCheckboxField.propTypes = propTypes
FCheckboxField.defaultProps = defaultProps

export default FCheckboxField
