import { getCookies } from 'libs/common/cookies'
import { POST, DELETE, withFingerprint } from 'utils/requests.utils'
import { ShallowResponse } from 'types/responses'

const BASE_URL = '/app/session'

export interface SignInParams {
  skipErrorNotific?: boolean
}

export interface SignInData {
  magicLinkToken?: string | string[] | boolean
  profileType?: string
  email?: string
  password?: string
}

export interface SignInResp {
  redirectUrl: string
  success: boolean
  expiresAt: string
  refreshExpiresAt: string
  accessToken: string
  refreshToken: string
}

interface ResetPasswordParams {
  email: string
  username: string
}

interface SubmitPasswordParams {
  resetPasswordToken: string
  password: string
  passwordConfirmation: string
}

export const signin = (data: SignInData, params: SignInParams) =>
  POST<ShallowResponse<SignInResp>>(
    BASE_URL,
    withFingerprint({
      ...data,
      contentType: 'application/json;charset=utf-8',
    }),
    params
  )

export const resetPassword = (data: ResetPasswordParams) =>
  POST(`${BASE_URL}/reset_password`, {
    ...data,
    contentType: 'application/json;charset=utf-8',
  })

export const submitPassword = (data: SubmitPasswordParams) =>
  POST(
    `${BASE_URL}/submit_password`,
    withFingerprint({
      ...data,
      contentType: 'application/json;charset=utf-8',
    })
  )

export const sendConfirmation = (data) =>
  POST(`${BASE_URL}/send_confirmation`, {
    ...data,
    contentType: 'application/json;charset=utf-8',
  })

export const submitConfirmation = async (data, params) =>
  POST<ShallowResponse<{ noUserDetected: boolean }>>(
    `${BASE_URL}/submit_confirmation`,
    await withFingerprint({
      ...data,
      contentType: 'application/json;charset=utf-8',
    }),
    params
  )

export const logOut = async () => DELETE(BASE_URL, await withFingerprint({ refreshToken: getCookies('refresh_token') }))
