import React from 'react'
import { EloCoinsIcon, EloHummerIcon, EloScalesIcon } from '@elo-ui/components/icons/regular'

const managerMenuConfigs = [
  {
    title: 'Overview',
    href: '/admin',
    icon: 'fas fa-tachometer-alt',
    key: 'static',
  },
  {
    title: 'Daily monitor',
    href: '#',
    icon: 'fas fa-exclamation-circle',
    children: [
      {
        title: 'Old Monitor',
        href: '/admin/static/monitor',
        key: 'monitor',
      },
      {
        title: 'Transfers Monitor',
        href: '/admin/static/transfers',
        key: 'transfers_monitor',
      },
      {
        title: 'Bank Wire Errors',
        href: '/admin/bank_wire_errors',
        key: 'bank_wire_errors',
      },
      {
        title: 'Need action transfers',
        href: '/admin/need_action_transfers',
        key: 'need_action_transfers',
      },
      {
        title: 'External transfers',
        href: '/admin/external_transfers',
        key: 'external_transfers',
      },
    ],
  },
  {
    title: 'Analysis',
    href: '#',
    icon: 'fas fa-chart-line',
    children: [
      {
        title: 'Top ...',
        href: '/admin/analysis/top_sellers',
        key: 'analysis',
      },
      {
        title: 'Business Intelligence',
        href: '/admin/business_intelligence',
        key: 'analysis',
      },
      {
        title: 'Old dashboard',
        href: '/admin/static/home',
        key: 'analysis',
      },
    ],
  },
  {
    title: 'Logs',
    href: '#',
    icon: 'fas fa-database',
    children: [
      {
        title: 'Contact',
        href: '/admin/sync_contact_logs',
        key: 'sync_contact_logs',
      },
      {
        title: 'Webhook',
        href: '/admin/sync_webhook_logs',
        key: 'sync_webhook_logs',
      },
      {
        title: 'Push notification',
        href: '/admin/push_notification_logs',
        key: 'mobile_app',
      },
      {
        title: 'Emails',
        href: '/admin/sync_email_logs',
        key: 'sync_email_logs',
      },
      {
        title: 'Tracking',
        href: '/admin/tracking_logs',
        key: 'tracking_logs',
      },
      {
        title: 'Badges',
        href: '/admin/badges_logs',
        key: 'badges_logs',
      },
      {
        title: 'Embed',
        href: '/admin/embed_logs',
        key: 'embed_logs',
      },
      {
        title: 'Profile',
        href: '/admin/sync_profile_logs',
        key: 'sync_profile_logs',
      },
      {
        title: 'Content Page Logs',
        href: '/admin/content_page_logs',
        key: 'content_page_logs',
      },
      {
        title: 'Csv logs',
        href: '/admin/csv_logs?default=true',
        key: 'csv_logs',
      },
      {
        title: 'Privacy stats',
        href: '/admin/privacy_stats',
        key: 'privacy_stats',
      },
      {
        title: 'VAT registration logs',
        href: '/admin/vat_registration_logs',
        key: 'vat_registration_logs',
      },
      {
        title: 'VAT ID Override',
        href: '/admin/vat_registration_exclusions',
        key: 'exclusions',
      },
      {
        title: 'Inkasso Logs',
        href: '/admin/claim_batches',
        key: 'inkasso_logs',
      },
    ],
  },
  {
    title: 'Users',
    href: '#',
    icon: 'fas fa-users',
    children: [
      {
        title: 'User profiles',
        href: '/admin/user_profiles',
        key: 'user_profiles',
      },
      {
        title: 'Users',
        href: '/admin/users',
        key: 'users',
      },
      {
        title: 'Managers',
        href: '/admin/managers',
        key: 'managers',
      },
      {
        title: 'Payers',
        href: '/admin/payers',
        key: 'payers',
      },
      {
        title: 'Publishers',
        href: '/admin/publishers',
        key: 'publishers',
      },
      {
        title: 'Elo Publishers',
        href: '/admin/elo_publishers',
        key: 'elo_publishers',
      },
      {
        title: 'Team Members',
        href: '/admin/team_members',
        key: 'team_members',
      },
      {
        title: 'Resellers',
        href: '/admin/resellers',
        key: 'resellers',
      },
      {
        title: 'Sellers',
        href: '/admin/sellers',
        key: 'sellers',
      },
      {
        title: 'Sales team members',
        href: '/admin/sales_team_members',
        key: 'sales_team_members',
      },
      {
        title: 'Deactivated Sellers',
        href: '/admin/deactivated_sellers',
        key: 'deactivated_sellers',
      },
    ],
  },
  {
    title: 'Emails',
    href: '#',
    icon: 'fas fa-envelope',
    children: [
      {
        title: 'Old Emails',
        href: '/admin/emails',
        key: 'emails',
      },
      {
        title: 'Wrappers',
        href: '/admin/email_wrappers',
        key: 'email_wrappers',
      },
      {
        title: 'Categories',
        href: '/admin/email_template_categories',
        key: 'email_template_categories',
      },
      {
        title: 'Templates',
        href: '/admin/email_templates',
        key: 'email_templates',
      },
    ],
  },
  {
    title: 'Sellers bundle',
    href: '#',
    icon: 'fas fa-database',
    children: [
      {
        title: 'Payment Settings',
        href: '/admin/payment_settings',
        key: 'payment_settings',
      },
      {
        title: 'Legitimations',
        href: '/admin/legitimations',
        key: 'legitimations',
      },
      {
        title: 'Wallets',
        href: '/admin/wallets',
        key: 'wallets',
      },
      {
        title: 'Documents',
        href: '/admin/documents',
        key: 'documents',
      },
      {
        title: 'Bank accounts',
        href: '/admin/bank_accounts',
        key: 'bank_accounts',
      },
      {
        title: 'Cancellation Terms',
        href: '/admin/cancellation_terms',
        key: 'cancellation_terms',
      },
      {
        title: 'Old VAT settings',
        href: '/admin/old_vat_settings',
        key: 'old_vat_settings',
      },
      {
        title: 'VAT settings',
        href: '/admin/vat_settings',
        key: 'vat_settings',
      },
      {
        title: 'VAT registrations',
        href: '/admin/vat_registrations',
        key: 'vat_registrations',
      },
      {
        title: 'Custom Domains',
        href: '/admin/domains',
        key: 'domains',
      },
      {
        title: 'Seller Settings',
        href: '/admin/seller_settings',
        key: 'seller_settings',
      },
      {
        title: 'Sharing links',
        href: '/admin/sharings',
        key: 'sharings',
      },
    ],
  },
  {
    title: 'Seller teams',
    href: '#',
    icon: 'fas fa-sitemap',
    children: [
      {
        title: 'Invites',
        href: '/admin/team_member_invites',
        key: 'team_member_invites',
      },
      {
        title: 'Roles',
        href: '/admin/team_member_roles',
        key: 'team_member_roles',
      },
      {
        title: 'Permissions',
        href: '/admin/team_member_role_permissions',
        key: 'permissions',
      },
      {
        title: 'Permission Category',
        href: '/admin/team_member_role_permission_categories',
        key: 'category_permissions',
      },
    ],
  },
  {
    title: 'Themes & pages',
    href: '#',
    icon: 'fas fa-columns',
    children: [
      {
        title: 'Templates',
        href: '/admin/shared_templates',
        key: 'shared_templates',
      },
      {
        title: 'Shop themes',
        href: '/admin/shop_themes',
        key: 'shop_themes',
      },
      {
        title: 'Membership themes',
        href: '/admin/membership_themes',
        key: 'membership_themes',
      },
      {
        title: 'Order Bump Themes',
        href: '/admin/addon_themes',
        key: 'products',
      },
      {
        title: 'Content Pages',
        href: '/admin/content_pages',
        key: 'content_pages',
      },
      {
        title: 'Content Blocks',
        href: '/admin/content_blocks',
        key: 'content_blocks',
      },
      {
        title: 'Content Page Categories',
        href: '/admin/content_page_categories',
        key: 'page_categories',
      },
      {
        title: 'Theme pages',
        href: '/admin/theme_pages',
        key: 'theme_pages',
      },
    ],
  },
  {
    title: 'Reseller bundle',
    href: '#',
    icon: 'fas fa-user-astronaut',
    withLabel: true,
    type: 'reseller',
    children: [
      {
        title: 'Products for review',
        href: '/admin/products_review?review_state=in_review',
        key: 'products_review',
      },
      {
        title: 'Reseller Requests',
        href: '/admin/reseller_requests',
        key: 'reseller_requests',
      },
      {
        title: 'Reseller Contracts',
        href: '/admin/reseller_contracts',
        key: 'reseller_contracts',
      },
      {
        title: 'Profiles for review',
        href: '/admin/profile_verifications',
        key: 'profile_verifications',
      },
    ],
  },
  {
    title: 'Products bundle',
    href: '#',
    icon: 'far fa-copy',
    children: [
      {
        title: 'Products',
        href: '/admin/products',
        key: 'products',
      },
      {
        title: 'Product upsells',
        href: '/admin/product_upsells',
        key: 'product_upsells',
      },
      {
        title: 'Lessons',
        href: '/admin/lessons',
        key: 'lessons',
      },
      {
        title: 'Product Assignments',
        href: '/admin/product_assignments',
        key: 'product_assignments',
      },
      {
        title: 'Tickets',
        href: '/admin/tickets',
        key: 'tickets',
      },
      {
        title: 'Tickets Dates',
        href: '/admin/ticket_dates',
        key: 'ticket_dates',
      },
      {
        title: 'Quizzes',
        href: '/admin/quizzes',
        key: 'quizzes',
      },
      {
        title: 'Pricing Plans',
        href: '/admin/pricing_plans',
        key: 'pricing_plans',
      },
      {
        title: 'Additional fees',
        href: '/admin/additional_fees',
        key: 'additional_fees',
      },
      {
        title: 'Coupons',
        href: '/admin/coupons',
        key: 'coupons',
      },
      {
        title: 'Tracking codes',
        href: '/admin/tracking_codes',
        key: 'tracking_codes',
      },
      {
        title: 'Funnel Nodes',
        href: '/admin/funnel_nodes',
        key: 'funnel_nodes',
      },
      {
        title: 'Funnels',
        href: '/admin/funnels',
        key: 'funnels',
      },
    ],
  },
  {
    title: 'Post sales',
    href: '#',
    icon: 'fas fa-piggy-bank',
    children: [
      {
        title: 'Products',
        href: '/admin/sold_products',
        key: 'products',
      },
      {
        title: 'Course sessions',
        href: '/admin/course_sessions',
        key: 'course_sessions',
      },
      {
        title: 'Lesson statuses',
        href: '/admin/lesson_statuses',
        key: 'lesson_statuses',
      },
      {
        title: 'Quiz attempts',
        href: '/admin/quiz_attempts',
        key: 'quiz_attempts',
      },
      {
        title: 'Payment tickets',
        href: '/admin/payment_tickets',
        key: 'payment_tickets',
      },
      {
        title: 'License keys',
        href: '/admin/license_keys',
        key: 'license_keys',
      },
    ],
  },
  {
    title: 'Transfers bundle',
    href: '#',
    icon: 'far fa-money-bill-alt',
    children: [
      {
        title: 'Transfers',
        href: '/admin/transfers',
        key: 'transfers',
      },
      {
        title: 'P2P Transfers',
        href: '/admin/p2p_transfers',
        key: 'p2p_transfers',
      },
      {
        title: 'Invoices',
        href: '/admin/invoices',
        key: 'invoices',
      },
      {
        title: 'Credit Memos',
        href: '/admin/credit_memos',
        key: 'credit_memos',
      },
      {
        title: 'Orders',
        href: '/admin/orders',
        key: 'orders',
      },
      {
        title: 'Order Rates',
        href: '/admin/order_rates',
        key: 'order_rates',
      },
      {
        title: 'Sellables',
        href: '/admin/sellables',
        key: 'sellables',
      },
      {
        title: 'Sellable Items',
        href: '/admin/sellable_items',
        key: 'sellable_items',
      },
      {
        title: 'Payment authorizations',
        href: '/admin/payment_authorizations',
        key: 'payment_authorizations',
      },
    ],
  },
  {
    title: 'Dunning',
    href: '#',
    icon: (
      <span className='elo-icon'>
        <EloScalesIcon size={26} />
      </span>
    ),
    children: [
      {
        title: 'All Sellers',
        href: '/admin/dunning/past_due_orders',
        subItems: ['/admin/dunning/past_due_orders', '/admin/dunning/open', '/admin/dunning/resolved'],
        key: 'dunning',
      },
    ],
  },
  {
    title: 'Payouts & Reports',
    href: '#',
    icon: 'fas fa-plug',
    children: [
      {
        title: 'Payouts / Cashouts',
        href: '/admin/payouts',
        key: 'payouts',
      },
      {
        title: 'Statistics',
        href: '/admin/payout_statistics',
        key: 'payout_statistics',
      },
      {
        title: 'Payout intervals',
        href: '/admin/payout_intervals',
        key: 'payout_intervals',
      },
      {
        title: 'Payout settings',
        href: '/admin/payout_settings',
        key: 'payout_intervals',
      },
      {
        title: 'Monthly invoices',
        href: '/admin/monthly_invoices',
        key: 'monthly_invoices',
      },
      {
        title: 'Reseller reports',
        href: '/admin/reseller_reports',
        key: 'reseller_reports',
      },
      {
        title: 'Financial reports',
        href: '/admin/financial_reports',
        key: 'financial_reports',
      },
    ],
  },
  {
    title: 'Inkasso Cashouts',
    href: '#',
    icon: (
      <span className='elo-icon'>
        <EloCoinsIcon size={22} />
      </span>
    ),
    children: [
      {
        title: 'Cashouts',
        href: '/admin/cashouts',
        key: 'inkasso_cashouts',
      },
    ],
  },
  {
    title: 'AML/Fraud',
    href: '#',
    icon: 'fas fa-users',
    children: [
      {
        title: 'Chargeback/Refund transfers Analysis',
        href: '/admin/fraud/chargeback_refund_analysis',
        key: 'fraud_analysis',
      },
      {
        title: 'Cancelled/Pending transfers Analysis',
        href: '/admin/fraud/cancelled_pending_analysis',
        key: 'fraud_analysis',
      },
      {
        title: 'Payment methods distribution Analysis',
        href: '/admin/fraud/payment_methods_analysis',
        key: 'fraud_analysis',
      },
    ],
  },
  {
    title: 'Affiliate',
    href: '#',
    icon: 'fas fa-users',
    children: [
      {
        title: 'Statistics',
        href: '/admin/affiliate/statistics',
        key: 'affiliate',
      },
      {
        title: 'Cookies',
        href: '/admin/affiliate_cookies',
        key: 'affiliate_cookies',
      },
      {
        title: 'Clicks',
        href: '/admin/affiliate_clicks',
        key: 'affiliate_clicks',
      },
      {
        title: 'Nodes',
        href: '/admin/affiliate_nodes',
        key: 'affiliate_nodes',
      },
      {
        title: 'Programs',
        href: '/admin/affiliate_programs',
        key: 'affiliate_programs',
      },
      {
        title: 'Landing pages',
        href: '/admin/affiliate_landings',
        key: 'affiliate_landings',
      },
      {
        title: 'Marketplace',
        href: '/admin/affiliate_marketplace',
        key: 'affiliate_marketplace',
      },
    ],
  },
  {
    title: 'Elo Affiliate',
    href: '#',
    icon: 'fas fa-users',
    children: [
      {
        title: 'Elo Publishers',
        href: '/admin/elo_publishers',
        key: 'elo_publishers',
      },
      {
        title: 'Affiliate redirections',
        href: '/admin/affiliate_redirections',
        key: 'affiliate_redirections',
      },
      {
        title: 'Affiliate clicks',
        href: '/admin/elo_publisher/affiliate_clicks',
        key: 'elo_publisher_affiliate_clicks',
      },
      {
        title: 'Applications',
        href: '/admin/elo_publisher/applications',
        key: 'elo_publisher_applications',
      },
      {
        title: 'Plans',
        href: '/admin/elo_publisher/plans',
        key: 'elo_publisher_plans',
      },
      {
        title: 'Commissions',
        href: '/admin/elo_publisher/commissions',
        key: 'elo_publisher_commissions',
      },
      {
        title: 'Statistics',
        href: '/admin/elo_publisher/statistics',
        key: 'elo_publisher_statistics',
      },
      {
        title: 'Statistic by seller',
        href: '/admin/elo_publisher/statistic_by_sellers',
        key: 'elo_publisher_statistics',
      },
    ],
  },
  {
    title: 'Costs',
    href: '#',
    icon: 'fas fa-dollar-sign',
    children: [
      {
        title: 'Option Categories',
        href: '/admin/option_categories',
        key: 'option_categories',
      },
      {
        title: 'Options',
        href: '/admin/options',
        key: 'options',
      },
      {
        title: 'Plans',
        href: '/admin/plans',
        key: 'plans',
      },
      {
        title: 'Subs sessions',
        href: '/admin/subs_sessions',
        key: 'subs_sessions',
      },
      {
        title: 'Subs charges',
        href: '/admin/subs_charges',
        key: 'subs_charges',
      },
      {
        title: 'Promo Codes',
        href: '/admin/subs_promo_codes',
        key: 'subs_promo_codes',
      },
      {
        title: 'Bonus Payments',
        href: '/admin/bonus_payments',
        key: 'bonus_payments',
      },
      {
        title: 'Referrals',
        href: '/admin/referrals',
        key: 'referrals',
      },
      {
        title: 'Subscriptions',
        href: '/admin/subscriptions',
        key: 'subscriptions',
      },
    ],
  },
  {
    title: 'Tax Declaration',
    href: '#',
    icon: (
      <span className='elo-icon'>
        <EloHummerIcon />
      </span>
    ),
    children: [
      {
        title: 'Datev Export',
        href: '/admin/datev_export',
        key: 'datev_export',
      },
      {
        title: 'Datev CSV logs',
        href: '/admin/tax_declaration_logs',
        key: 'datev_export_csv',
      },
    ],
  },
  {
    title: 'Internal Stuff',
    href: '#',
    icon: 'fas fa-cog',
    children: [
      {
        title: 'Admin Notes',
        href: '/admin/admin_notes',
        key: 'user_notes',
      },
      {
        title: 'Contact states',
        href: '/admin/contact_states',
        key: 'contact_states',
      },
      {
        title: 'Webhook endpoint',
        href: '/admin/webhook_endpoints',
        key: 'webhook_endpoints',
      },
      {
        title: 'Webhook event',
        href: '/admin/webhook_events',
        key: 'webhook_events',
      },
      {
        title: 'Manager permissions',
        href: '/admin/manager_permissions',
        key: 'manager_permissions',
      },
      {
        title: 'Currencies',
        href: '/admin/currencies',
        key: 'multicurrency',
      },
      {
        title: 'Contract template',
        href: '/admin/contract_templates',
        key: 'contract_templates',
      },
      {
        title: 'Privacy template',
        href: '/admin/privacy_templates',
        key: 'privacy_templates',
      },
      {
        title: 'Privacy template categories',
        href: '/admin/privacy_template_categories',
        key: 'privacy_template_categories',
      },
      {
        title: 'Cookies Consent',
        href: '/admin/cookies_consents',
        key: 'cookies_consents',
      },
      {
        title: 'Cookies Consent Categories',
        href: '/admin/cookies_consent_categories',
        key: 'cookies_consent_categories',
      },
      {
        title: 'Landing Pages',
        href: '/admin/landing_pages',
        key: 'landing_pages',
      },
      {
        title: 'Media files',
        href: '/admin/media_files',
        key: 'media_files',
      },
      {
        title: 'Ablefy promos',
        href: '/admin/elopage_promos',
        key: 'elopage_promos',
      },
      {
        title: 'Sign up questions',
        href: '/admin/sign_up_questions',
        key: 'sign_up_questions',
      },
      {
        title: 'Settings',
        href: '/admin/settings',
        key: 'settings',
      },
      {
        title: 'Docs',
        href: '/admin/docs',
        key: 'docs',
      },
    ],
  },
  {
    title: 'HMAC keys',
    href: '#',
    icon: 'fas fa-key',
    children: [
      {
        title: 'HMAC keys',
        href: '/admin/hmac_keys',
        key: 'hmac_keys',
      },
    ],
  },
  {
    title: 'Token ID',
    href: '#',
    icon: 'fas fa-key',
    children: [
      {
        title: 'Token Id',
        href: '/admin/token_ids',
        key: 'token_ids',
      },
    ],
  },
  {
    title: 'OLD (to remove)',
    href: '#',
    icon: 'fas fa-trash',
    children: [
      {
        title: 'VAT rates calculator',
        href: '/admin/vat_rates',
        key: 'vat_rates',
      },
      {
        title: 'Sepa Direct debit - will be integrated in settings',
        href: '/admin/sepa_direct_debit',
        key: 'sepa_direct_debits',
      },
    ],
  },
  {
    title: I18n.t('react.shared.nav.settings'),
    href: '#',
    icon: 'fas fa-cog',
    children: [
      {
        href: '/admin/profile/edit',
        title: I18n.t('react.shared.nav.profile'),
      },
    ],
  },
]

export default managerMenuConfigs
