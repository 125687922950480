import { makeObservable, override, toJS } from 'mobx'

import * as api from '../api/eloPublisher.api'
import SharedStore from './shared.store'

export class EloPublisherStore extends SharedStore {
  storeName = 'EloPublisherStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  @override
  async fetchItem(id, data) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem({
      expand: toJS(this.expands),
      ...data,
    })
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  constructor() {
    super()

    makeObservable(this)

    this.expands = ['user_profile']
  }
}

export default new EloPublisherStore()
