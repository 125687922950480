import { profile } from 'utils/profileHelper.utils'
import { computed, makeObservable } from 'mobx'
import { getCookies, setCookies } from 'libs/common/cookies'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/salesTeamMember.api'

export class SalesTeamMemberStore extends SharedStore {
  storeName = 'SalesTeamMemberStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()

    makeObservable(this)
  }

  setSelectedSalesTeamId() {
    let tmSelectedTeamId = getCookies('tm_sales_team_id')

    if (!tmSelectedTeamId) {
      const { salesTeamMemberInvites } = this.item
      const firstAcceptedTeam = (salesTeamMemberInvites || []).find((i) => i.state === 'accepted')
      tmSelectedTeamId = firstAcceptedTeam?.salesTeamId
      setCookies('tm_sales_team_id', firstAcceptedTeam?.salesTeamId)
    }

    profile.setTmSelectedTeamId(String(tmSelectedTeamId))
    return tmSelectedTeamId
  }

  @computed get selectedSalesTeamId() {
    if (!profile.tmSelectedTeamId) {
      this.setSelectedSalesTeamId()
    }
    return Number(profile.tmSelectedTeamId)
  }

  async acceptTerms() {
    this.toggleLoading(true)
    const resp = await this.childApi.acceptTerms()
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }
}

export default new SalesTeamMemberStore()
